import React from "react";
import { graphql, navigate } from "gatsby";
import { Helmet } from 'react-helmet';
import PostListing from '../components/PostListing';
import Paginator from 'react-paginate';
import { range, kebabCase } from 'lodash'
import Layout from '../components/layout'

export default class taguTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { totalCount } = data.allMarkdownRemark
    const { group, index, pageCount, additionalContext } = pageContext
    const tag = additionalContext.tag;
    const kCTag = kebabCase(tag)
    
    const paginatorChange = ({ selected }) =>
      navigate(`/tag/${kCTag}/${selected > 0 ? selected + 1 : ''}`)
    const paginator = (<div className='paginator'><Paginator
      pageCount={ pageCount }
      pageRangeDisplayed={ pageCount }
      marginPagesDisplayed={ 3 }
      initialPage={ index - 1 }
      onPageChange={ paginatorChange }
      /></div>)
    return (
      <Layout location={this.props.location}>
        <article className='page'>
          <h1>"{tag}" posts</h1>
          <PostListing postEdges={group} />
          { (pageCount > 1) ? paginator : null }
        </article>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query TagsuPage {
    allMarkdownRemark {
      totalCount
    }
  }
`
